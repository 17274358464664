import {
  List,
  Datagrid,
  TextField,
  ResourceComponentProps,
  TextInput,
  Show,
  SimpleShowLayout,
  NumberField,
  ArrayField,
  ImageField,
  ListActionsProps,
  useNotify,
  TopToolbar,
  FilterButton,
  ExportButton,
  useRefresh,
  Identifier,
  useListContext,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";
import { Button } from "@material-ui/core";
import axios from "network";

const WorkshopListFilters = [
  <TextInput source="id" />,
  <TextInput source="fiscalNumber" />,
  <TextInput source="name" />,
  <TextInput source="city" />,
  <TextInput source="postalCode" />,
  <TextInput source="country" />,
];

const WorkshopListActions = (props: ListActionsProps) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const syncWorkshops = async () => {
    try {
      const res = await axios.post("workshops/sync");
      refresh();
      notify(`${res.data.count} workshops synced`);
    } catch (error: any) {
      notify(`Could not submit booking: ${error.message}`);
    }
  }

  return (
    <TopToolbar>
      <Button variant="contained" color="primary" onClick={() => syncWorkshops()}>
        Sync
      </Button>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};


const noSelection: Identifier[] = [];

const BulkActivateButton = () => {
  const { selectedIds = noSelection } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll('workshops');
  
  const [updateMany] = useUpdateMany(
    'workshops',
    selectedIds, { status: 'Active' },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify(`Updated ${selectedIds.length} workshops to 'Active'`, {
          type: 'info',
          undoable: true,
        });
        unselectAll();
      },            
      onFailure: () => {
        notify(`An error occured when performing a bulk activation`, {
          type: 'error',
        });
      },
    }
  );

  return (
    <Button 
      onClick={() => updateMany()}>
      Activate
    </Button>
  );
};

const BulkDeleteButton = () => {
  const { selectedIds = noSelection } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll('workshops');
  
  const [updateMany] = useUpdateMany(
    'workshops',
    selectedIds, { status: 'Deleted' },
    {
      mutationMode: 'undoable',
      onSuccess: () => {            
        notify(`Updated ${selectedIds.length} workshops to 'Deleted'`, {
          type: 'info',
          undoable: true,
        });
        unselectAll();
      },            
      onFailure: () => {            
        notify(`An error occured when performing a bulk deletion`, {
          type: 'error',
        });
      },
    }
  );

  return (
    <Button 
      onClick={() => updateMany()}>
      Delete
    </Button>
  );
};

const BulkActions = () => (
  <>
    <BulkActivateButton />
    <BulkDeleteButton />
  </>
)

export const WorkshopList = (props: ResourceComponentProps) => (
  <List {...props} bulkActionButtons={<BulkActions />} filters={WorkshopListFilters} actions={<WorkshopListActions />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
      <TextField source="status" />
      <TextField source="name" />
    </Datagrid>
  </List>
);


export const WorkshopShow = (props: ResourceComponentProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
      <TextField source="status" />
      <TextField source="name" />
      <ImageField source="coverImage" />
      <TextField source="fiscalNumber" />
      <TextField source="email" />
      <TextField source="street" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="postalCode" />
      <TextField source="country" />
      <TextField source="pointOfReference" />
      <NumberField source="latitude" />
      <NumberField source="longitude" />

      <ArrayField source="openHours">
        <Datagrid>
          <TextField source="type" />
          <NumberField source="weekDay" />
          <TextField source="startTime" />
          <TextField source="endTime" />
        </Datagrid>
      </ArrayField>

      <ArrayField source="services">
        <Datagrid>
          <TextField source="id" />
          <TextField source="salesforceId" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>

      <ArrayField source="categories">
        <Datagrid>
          <TextField source="id" />
          <TextField source="salesforceId" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>

      <ArrayField source="perks">
        <Datagrid>
          <TextField source="id" />
          <TextField source="salesforceId" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>

      <TextField source="categoriesRadius" />
    </SimpleShowLayout>
  </Show>
);
